// Global imports
import $ from 'jquery';


export default function() {
    const navTrigger  = $('.nav-trigger');

    // Open on mobile
    navTrigger.on('click', function() {
        $(this).toggleClass('active');
        $('.page-nav').toggleClass('opened');
    });
}
