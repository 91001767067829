// Global imports
import $ from 'jquery';


export default () => {
    const hide = function() {
        const preloader = $('#preloader');
        preloader.addClass('hide-me');
    };

    $(document).ready(() => {
        hide();
    });
}
