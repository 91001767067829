//* Basic
import $ from 'jquery';

//* Google Analitics

//* Components
import cookies from './modules/cookies';
import preloader from './modules/preloader';
import menu from './modules/menu';
import slider from './modules/slider';
import './modules/events';

//* Init modules
$(document).ready(() => {
    preloader();
    menu();
    cookies();
    slider();
});
