// Global imports
import $ from 'jquery';


//* Global cookie class
const
    cookieCloseBTN          = '.js-close-cookies',
    cookieActiveClassName   = 'show';


//* Single cookie param.
const
    cookiesID     = ['#cookie-box-modal'],
    cookiesName   = ['rodp'],
    cookiesValue  = [true],
    cookiesExpire = [30]; // Number days after cookie expire


// Export cookies function
export default () => {
    //* Show cookie if it's not exist
    for (let i = 0; i < cookiesName.length; i++) {
        if (document.cookie.indexOf(cookiesName[i]) < 0) {
            $(cookiesID[i]).addClass(cookieActiveClassName);
        }
    }


    //* Close cookie fonction for single cookie
    for (let i = 0; i < cookiesID.length; i++) {
        const
            $target   = $(cookiesID[i]),
            $closeID  = $target.find(cookieCloseBTN);

        closeCookie($closeID, $target, cookiesName[i], cookiesValue[i], cookiesExpire[i]);
    }
};


//* Close cookie fonction
export const closeCookie = (closeID, target, name, value, expire) => {
    closeID.on('click', e => {
        e.preventDefault();
        setCookie(name, value, expire);
        target.removeClass(cookieActiveClassName);
    });
};


//* Create cookie with name, value and time to expire
export const setCookie = (name, value, days) => {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `; expires=${d.toUTCString()}`;
    document.cookie = `${name}=${value}${expires}; path=/`;
};
