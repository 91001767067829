import $ from 'jquery';

$(document).ready(() => {

  $('.gallery').on('click', e => {
    
    if($(e.target).parent().parent().data('src') !== undefined) {
      $('.modal-inner').find('img').attr('src',$(e.target).parent().parent().data('src'));
      $('.modal-img').addClass('show-modal');
      $('html').css({
        "overflow-y": "hidden"
      });
    }
    
  });

  $('.close-btn').on('click', () => {
    $('.modal-img').removeClass('show-modal');
    $('html').css({
      "overflow-y": ""
    });
  });

});
